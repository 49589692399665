import { type FC, type FormEvent, memo, useCallback, useState, useContext } from 'react'
import { signInWithPopup, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth'
import { Button, Divider, TextField, Snackbar, Alert, Link, Typography, IconButton, InputAdornment, Zoom, Stack } from '@mui/material'
import { BiShow, BiHide } from 'react-icons/bi'
import { auth, googleAuthProvider } from '../firebase'
import { Center, Footer, Logo } from '../components'
import { FcGoogle } from 'react-icons/fc'
import { AlertContext } from '../providers'
// import { useNavigate } from 'react-router-dom'

const SignInForm: FC = () => {
    const [showSignUpForm, setShowSignUpForm] = useState(false)
    const [email, setEmail] = useState('')
    const [emailCreate, setEmailCreate] = useState('')
    const [password, setPassword] = useState('')
    const [passwordCreate, setPasswordCreate] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [loginLoading, setLoginLoading] = useState(false)
    const [infoSnackbarOpen, setInfoSnackbarOpen] = useState(false)
    const [loginError, setLoginError] = useState('')
    const [loginPasswordError, setLoginPasswordError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [passwordMatchError, setPasswordMatchError] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [showPasswordCreate, setShowPasswordCreate] = useState(false)
    const [showPasswordConfirm, setShowPasswordMatch] = useState(false)
    const handleClickShowPassword = () => { setShowPassword((show) => !show) }
    const handleClickShowPasswordCreate = () => { setShowPasswordCreate((showCreate) => !showCreate) }
    const handleClickShowPasswordConfirm = () => { setShowPasswordMatch((showMatch) => !showMatch) }
    const setAlert = useContext(AlertContext)
    // const navigate = useNavigate()

    const handleShowSignUpForm = () => {
        setShowSignUpForm(true)
    }

    const handleHideSignUpForm = () => {
        setShowSignUpForm(false)
    }

    const checkEmailValidiation = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const rgExp = /^[a-zA-Z0-9._]+@[a-zA-Z]+\.[a-zA-Z]{2,6}$/
        if (rgExp.test(email)) {
            handleSignIn()
        } else if (email === '') {
            setLoginError('You must enter an email to continue')
        } else if (!rgExp.test(email)) {
            setLoginError('Please enter a valid email')
        }
    }

    const checkEmailPasswordCreationValidation = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const rgExp = /^[a-zA-Z0-9._]+@[a-zA-Z]+\.[a-zA-Z]{2,6}$/
        if (rgExp.test(emailCreate)) {
            if (passwordCreate === passwordConfirm) {
                handleCreateUser()
            }
        } else if (emailCreate === '') {
            setEmailError('You must enter an email to continue')
        } else if (!rgExp.test(emailCreate)) {
            setLoginError('Please enter a valid email')
        }

        if (passwordCreate === '') {
            setPasswordError('Please enter a password')
        } else if (passwordCreate.length < 6) {
            setPasswordError('Your password must have at least 6 characters')
        } else if (passwordConfirm === '') {
            setPasswordMatchError('Please confirm your password')
        } else if (passwordConfirm !== passwordCreate) {
            setPasswordMatchError('Password does not match')
        }
    }

    const handleCreateUser = () => {
        setEmailError('')
        setPasswordError('')
        setPasswordMatchError('')
        createUserWithEmailAndPassword(auth, emailCreate, passwordCreate)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user
                console.log('Logged in as', (user.displayName !== null) ? user.displayName : user.email)
                // ...
            })
            .catch((error) => {
                const errorCode = error.code
                const errorMessage = error.message
                console.log(errorCode, errorMessage)
                setEmailError(errorCode)
                // ..
            })
    }

    const handleGoogleSignIn = useCallback(async () => {
        try {
            await signInWithPopup(auth, googleAuthProvider)
        } catch (error) {
            setAlert({
                severity: 'error',
                content: 'Failed to sign in with Google'
            })
        }
    }, [])

    const handleSignIn = () => {
        setLoginLoading(true)
        setLoginError('')
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
            // Signed in
                const user = userCredential.user
                console.log(user)
            // ...
            })
            .catch((error) => {
                const errorCode = error.code
                const errorMessage = error.message
                console.log(errorCode, errorMessage)
                setLoginPasswordError('Login failed. Please check your email and password, then try again.')
            }).finally(() => {
                setLoginLoading(false)
            })
    }

    // const handleGoogleSignIn = useCallback(() => {
    //     void signInWithPopup(auth, googleAuthProvider)
    // }, [])

    // const handleMicrosoftSignIn = useCallback(() => {
    //     void signInWithRedirect(auth, microsoftAuthProvider)
    // }, [])

    const toggleInfoSnackbar = useCallback(() => {
        setInfoSnackbarOpen((infoSnackbarOpen) => !infoSnackbarOpen)
    }, [])

    return (
        <Center>
            <Zoom in>
                <Stack
                    spacing={2}
                    width={350}
                    maxWidth={350}
                    component='form'
                    onSubmit={ showSignUpForm ? checkEmailPasswordCreationValidation : checkEmailValidiation }
                >
                    <Logo height={80} />
                    {showSignUpForm
                        ? (
                            <>
                                <Link
                                    sx={{ cursor: 'pointer' }}
                                    onClick={handleHideSignUpForm}
                                >
                                    <Typography
                                        variant='body2'
                                        sx={{
                                            color: 'text.secondary',
                                            textAlign: 'center'
                                        }}
                                    >
                                    Already have an account? Log in here.
                                    </Typography>
                                </Link><TextField
                                    error={Boolean(emailError)}
                                    helperText={emailError}
                                    className="signInButton"
                                    type='email'
                                    label="Email address"
                                    value={emailCreate}
                                    onChange={(e) => {
                                        setEmailCreate(e.target.value)
                                    } } /><TextField
                                    error={Boolean(passwordError)}
                                    helperText={passwordError}
                                    className="signInButton"
                                    type={showPasswordCreate ? 'text' : 'password'}
                                    label="Create Password"
                                    value={passwordCreate}
                                    onChange={(e) => {
                                        setPasswordCreate(e.target.value)
                                    } }
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPasswordCreate}
                                                edge="end"
                                            >
                                                {showPasswordCreate ? <BiShow /> : <BiHide />}
                                            </IconButton>
                                        </InputAdornment>
                                    }} /><TextField
                                    error={Boolean(passwordMatchError)}
                                    helperText={passwordMatchError}
                                    className="signInButton"
                                    type={showPasswordConfirm ? 'text' : 'password'}
                                    label="Confirm Password"
                                    value={passwordConfirm}
                                    onChange={(e) => {
                                        setPasswordConfirm(e.target.value)
                                    } }
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPasswordConfirm}
                                                edge="end"
                                            >
                                                {showPasswordConfirm ? <BiShow /> : <BiHide />}
                                            </IconButton>
                                        </InputAdornment>
                                    }} />
                                <Button className="signInButton" variant='contained' type='submit' disabled={loginLoading}>
                                    {loginLoading ? ' LOADING...' : 'Register'}
                                </Button>
                            </>
                        )
                        : (
                            <>
                                <Link
                                    sx={{ cursor: 'pointer' }}
                                    onClick={handleShowSignUpForm}
                                >
                                    <Typography
                                        variant='body2'
                                        sx={{
                                            color: 'text.secondary',
                                            textAlign: 'center'
                                        }}
                                    >
                                            Need an account? Click here to sign up.
                                    </Typography>
                                </Link>
                                <TextField
                                    error={Boolean(loginError)}
                                    helperText={loginError}
                                    className="signInButton"
                                    type='email'
                                    label="Email address"
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                    } } />
                                <TextField
                                    error={Boolean(loginPasswordError)}
                                    helperText={loginPasswordError}
                                    className="signInButton"
                                    type={showPassword ? 'text' : 'password'}
                                    label="Password"
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value)
                                    } }
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <BiShow /> : <BiHide />}
                                            </IconButton>
                                        </InputAdornment>
                                    }} />
                                <Button className="signInButton" variant='contained' type='submit' disabled={loginLoading}>
                                    {loginLoading ? ' LOADING...' : 'CONTINUE'}
                                </Button>
                                <Divider>Or</Divider>
                                <Button startIcon={<FcGoogle />} className="signInButton" onClick={handleGoogleSignIn}>
                                            Continue with Google
                                </Button></>
                        )}
                    <Snackbar open={infoSnackbarOpen} autoHideDuration={6000} onClose={toggleInfoSnackbar}>
                        <Alert onClose={toggleInfoSnackbar} severity="success">
                        A sign in link has been sent to your email
                        </Alert>
                    </Snackbar>
                    <Footer />
                </Stack>
            </Zoom>
        </Center>
    )
}

export default memo(SignInForm)
