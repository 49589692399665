import type { IconType } from 'react-icons'
import { FiFolder, FiServer, FiDatabase } from 'react-icons/fi'
import { FcGoogle } from 'react-icons/fc'

const Icons: Record<string, IconType | undefined> = {
    FiFolder,
    FiServer,
    FiDatabase,
    FcGoogle
}

export default Icons
